import React from 'react';
import loadable from '@loadable/component';

import IntroContent from '../../content/IntroContent.json';
// import MiddleBlockContent from '../../content/MiddleBlockContent.json';
import AIContent from '../../content/AIContent.json';
import AutomationContent from '../../content/Automation.json';
import ProductContent from '../../content/ProductContent.json';
import ContactContent from '../../content/ContactContent.json';

const ContactFrom = loadable(() => import('../../components/ContactForm'));
const ContentBlock = loadable(() => import('../../components/ContentBlock'));
//const MiddleBlock = loadable(() => import('../../components/MiddleBlock'));
const Container = loadable(() => import('../../common/Container'));
const ScrollToTop = loadable(() => import('../../common/ScrollToTop'));

const Home = () => {
  return (
    <Container>
      <ScrollToTop />
      <ContentBlock
        type="right"
        first="true"
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="school.svg"
        id="intro"
      />
      {/* <MiddleBlock
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        button={MiddleBlockContent.button}
      /> */}
      <ContentBlock
        type="left"
        title={AIContent.title}
        content={AIContent.text}
        section={AIContent.section}
        icon="aimodel.svg"
        id="about"
      />
      <ContentBlock
        type="right"
        title={AutomationContent.title}
        content={AutomationContent.text}
        icon="automation.svg"
        id="mission"
      />

      <ContentBlock
        type="left"
        title={ProductContent.title}
        content={ProductContent.text}
        icon="cofund1.svg"
        id="product"
      />
      <ContactFrom
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      />
    </Container>
  );
};

export default Home;
